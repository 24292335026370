<template>
  <div class="addStudent-dialog">
    <base-dialog
      :model="addData.isOpen"
      @close="closeDialogHandle"
      :title="addData.title"
      width="35%"
    >
      <template #body>
        <el-form
          ref="form"
          :rules="rules"
          :model="formData"
          label-width="100px"
        >
          <el-form-item label="选择时间" prop="startDate">
            <el-col :span="11">
              <el-date-picker
                type="datetime"
                placeholder="开始时间"
                v-model="formData.startDate"
                style="width: 100%"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-col>
            <el-col class="line" style="text-align: center" :span="2">-</el-col>
            <el-col :span="11">
              <el-date-picker
                type="datetime"
                placeholder="结束时间"
                v-model="formData.endDate"
                style="width: 100%"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-col>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="addData.isOpen = false">取 消</el-button>
        <el-button type="primary" @click="confirmHandle">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { Message } from 'element-ui'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        startDate: '',
        endDate: '',
        tradeType: 'partTimeTuitionFee',
        collegeId: '',
        majorId: '',
        classesId: '',
        payMethod: '移动支付'
      },
      collegeList: [],
      majorList: [],
      classList: [],
      rules: {
        // startDate: [{ required: true, message: '请选择院系', trigger: 'blur' }],
        // startDate: [{ required: true, message: '请选择院系', trigger: 'blur' }]
      }
    }
  },
  watch: {
    'addData.isOpen': function (isOpen) {
      if (!isOpen) {
        this.formData = {
          startDate: '',
          endDate: '',
          collegeId: '',
          tradeType: 'partTimeTuitionFee',
          majorId: '',
          payMethod: '移动支付',
          classesId: ''
        }
      }
    }
  },
  methods: {
    // 确定事件
    confirmHandle () {
      if (this.formData.startDate == null || this.formData.startDate === '' || this.formData.endDate == null || this.formData.endDate === '') {
        Message.info({ message: '请先选择开始和结束时间!' })
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('confirm', this.formData)
          this.closeDialogHandle()
        }
      })
    },
    // 关闭弹窗
    closeDialogHandle () {
      this.addData.isOpen = false
    }
  }
}
</script>
