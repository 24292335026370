export const orderStatusList = [{
  type: 1,
  name: '待支付'
}, {
  type: 2,
  name: '成功'
}, {
  type: 3,
  name: '失败'
}, {
  type: 4,
  name: '全部退款'
}, {
  type: 5,
  name: '部分退款'
}, {
  type: 6,
  name: '失效'
}, {
  type: 7,
  name: '待退款'
}, {
  type: 8,
  name: '退款失败'
}]
export const payTypeList = [{
  type: 1,
  name: '扣费'
}, {
  type: 2,
  name: '现金'
}, {
  type: 3,
  name: '刷卡'
}, {
  type: 4,
  name: '汇款'
}, {
  type: 5,
  name: '助贷'
}, {
  type: 6,
  name: '减免'
}, {
  type: 7,
  name: '移动支付'
}, {
  type: 9,
  name: '移动支付:中行'
}, {
  type: 8,
  name: '其他'
}]
